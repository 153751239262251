<template>
  <h2 class="page-title">List Settings</h2>
  <Loader v-if="!pageReady" />
  <p class="error" v-if="criticalError">{{ criticalError }}</p>
  <form v-if="pageReady && !criticalError" v-on:submit.prevent="saveList">
    <div class="form-group">
      <p class="error" v-if="error">{{ error }}</p>
      <div class="form-input">
        <label>Title</label>
        <input type="text" v-model="title" placeholder="Enter Title" v-bind:class="[titleError ? 'error' : '']">
        <div class="form-error" v-if="titleError">{{ titleError }}</div>
      </div>
      <div class="form-input">
        <label>Theme</label>
        <select v-model="theme">
          <option value="">Classic</option>
          <option value="dark">Dark</option>
          <option value="sunset">Sunset</option>
          <option value="mardigras">Mardi Gras</option>
          <option value="usa">USA</option>
          <option value="spooky">Spooky</option>
          <option value="fall">Fall</option>
          <option value="holiday">Holiday</option>
          <option value="jingle">Jingle</option>
          <option value="santa">Santa</option>
        </select>
      </div>
      <div class="form-input">
        <label>Visibility</label>
        <select v-model="visibility">
          <option value="1">Public</option>
          <option value="0">Private</option>
        </select>
      </div>
      <div class="form-input">
        <label>Members</label>
        <div class="users-selected">
          <div class="user-selected" v-for="user in selectedUsers" :key="user.id" @click="selectUser(user)">
            {{ user.username }}
          </div>
        </div>
        <!-- <div class="users-filter">
          <input type="text" placeholder="Search">
        </div> -->
        <div class="users-selector">
          <InfiniteLoader class="scroll" @handler="infiniteHandler">
            <div class="user-option" v-for="user in users" v-bind:key="user.id" @click="selectUser(user)" v-bind:class="{ active: selectedUsers[user.id] }">
              <img :src="apiUrl + user.image" :alt="user.username">
              <div class="username">{{ user.username }}</div>
            </div>
          </InfiniteLoader>
        </div>
      </div>
    </div>
    <div class="form-input form-actions">
      <Loader v-if="isLoading" />
      <button v-if="!isLoading" class="btn" type="submit">Save List</button>
    </div>
  </form>
</template>

<script>
import InfiniteLoader from '@/components/InfiniteLoader.vue'
import Loader from '@/components/Loader.vue'
import axios from 'axios'
import authHeader from '../services/auth-header'
import CacheService from '../services/cache.service'
import validator from '../services/validator'

const API_URL = process.env.VUE_APP_API_URL || 'https://api.socia-lists.com'

export default {
  name: 'ListSettings',
  components: {
    InfiniteLoader,
    Loader
  },
  data () {
    return {
      pageReady: false,
      isLoading: false,
      error: '',
      criticalError: '',
      title: '',
      titleError: '',
      theme: '',
      visibility: '',
      apiUrl: API_URL,
      page: 0,
      users: [],
      selectedUsers: {}
    }
  },
  created () {
    this.getList(this.$route.params.id)
    CacheService.load('users-cache')
  },
  methods: {
    getList: function (id) {
      axios
        .get(API_URL + '/lists/' + id, {
          headers: authHeader()
        })
        .then(response => {
          // console.log(response)
          this.pageReady = true
          this.title = response.data.title
          this.theme = response.data.theme
          this.visibility = response.data.visibility
          if (response.data.members) {
            for (var i = 0; i < response.data.members.length; i++) {
              this.selectedUsers[response.data.members[i].id] = response.data.members[i]
            }
          }
        })
        .catch(error => {
          // console.log(error)
          this.pageReady = true
          if (error.response && error.response.status === 403) {
            this.criticalError = 'You do not have permission to edit this list.'
          } else {
            this.criticalError = 'Uh oh! Something went wrong.'
          }
        })
    },
    infiniteHandler: function (stateHandler) {
      if (stateHandler.isFirst && CacheService.isValid()) {
        const cache = CacheService.get()
        this.users = cache.data
        this.page = cache.current_page
        if (cache.current_page >= cache.last_page) {
          stateHandler.finished()
        } else {
          stateHandler.complete()
        }
      } else {
        this.loadUsers(stateHandler)
      }
    },
    loadUsers: function (stateHandler) {
      axios
        .get(API_URL + '/users?page=' + (this.page + 1), {
          headers: authHeader()
        })
        .then(response => {
          // console.log(response.data)
          this.users.push(...response.data.data)
          CacheService.set({
            data: this.users,
            current_page: response.data.current_page,
            last_page: response.data.last_page
          })
          this.page = response.data.current_page
          if (response.data.current_page < response.data.last_page) {
            stateHandler.complete()
          } else {
            stateHandler.finished()
          }
        })
        .catch(error => {
          console.log(error)
          stateHandler.finished()
          this.criticalError = 'Error loading users.'
        })
    },
    selectUser: function (user) {
      if (this.selectedUsers[user.id]) {
        delete this.selectedUsers[user.id]
      } else {
        this.selectedUsers[user.id] = user
      }
    },
    validate: function () {
      this.titleError = ''
      if (validator.isEmpty(this.title)) {
        this.titleError = 'Title is required.'
        return false
      }
      return true
    },
    saveList: function () {
      if (this.validate()) {
        this.isLoading = true
        axios
          .put(API_URL + '/lists/' + this.$route.params.id, {
            id: this.$route.params.id,
            title: this.title,
            theme: this.theme,
            visibility: parseInt(this.visibility),
            users: Object.keys(this.selectedUsers)
          }, {
            headers: authHeader()
          })
          .then(response => {
            // console.log(response)
            this.isLoading = false
            CacheService.removeByKey('dashboard-cache-user')
            CacheService.removeByKey('dashboard-cache-group')
            CacheService.removeByKey('search-cache')
            this.$router.push('/dashboard')
          })
          .catch(error => {
            this.isLoading = false
            console.log(error)
          })
      }
    }
  }
}
</script>
